<template>
  <div>
    <span class="mr-3 text-indigo-900">Statut</span>
    <div class="w-28 lg:w-52">
      <multiselect
        placeholder=""
        select-label=""
        deselect-label=""
        selected-label=""
        track-by="value"
        label="label"
        :options="options"
        :value="value"
        :multiple="true"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
import { isAdmin } from "@/helpers/user";

const OPTIONS_ADMIN = [
  { label: "Inactif", value: "INACTIF" },
  { label: "Actif", value: "ACTIF" },
  { label: "Terminé", value: "TERMINE" },
  { label: "Envoyé", value: "ENVOYE" },
];

const OPTIONS_USER = [
  { label: "À faire", value: "A_FAIRE" },
  { label: "Envoyé", value: "ENVOYE" },
  { label: "Validé", value: "VALIDE" },
];

export default {
  name: "StatusFilter",
  props: {
    value: {
      type: [Array, Object],
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  computed: {
    options() {
      return isAdmin() ? OPTIONS_ADMIN : OPTIONS_USER;
    },
  },
  methods: {
    handleInput(value) {
      this.$emit("input", { field: this.fields[0], value });
    },
  },
};
</script>
